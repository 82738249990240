footer {
  svg {
    width: 5.5rem;
  }

  nav {
    font-size: $h5-font-size;

    ul {
      @include list-unstyled();

      li {

      }
    }
  }

  #credits {
    font-size: .8rem;
    margin: 0;

    a {
      @extend .font-weight-bold;
      color: $black;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
