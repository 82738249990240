/*
 * Desplegar menúes sin javascript!
 *
 * Inspirado en https://dev.to/cydstumpel/creating-a-fold-out-navigation-with-css-only-4g9k
 */

// Comenzamos con un input checkbox con la clase toggler
input.d_toggler {
  // Lo ocultamos pero todavía queremos que se le pueda dar foco con el
  // teclado
  opacity: 0;
  position: absolute;

  // Ocultamos el elemento colapsable
  & ~ label {
    .telon,
    & + .d_toggled {
      z-index: 10;
      visibility: hidden;

    }
  }

  // Al tildar el input, mostramos el elemento
  &:checked ~ label {
    .telon,
    & + .d_toggled {
      visibility: visible;
    }
  }

  & + label {
    border: none;
    outline: none;
    z-index: 11;
  }

  // Dar una guía visual de que estamos enfocando el input
  &:focus + label {
    outline: 1px dotted grey ;
  }
}

input.inverted-toggler {
  & ~ label {
    & + .d_toggled {
      z-index: 10;
      visibility: visible;
    }
  }

  &:checked ~ label {
    & + .d_toggled {
      visibility: hidden;
      display: none;
    }
  }

  & + label {
    border: none;
    outline: none;
    z-index: 11;
  }

  // Dar una guía visual de que estamos enfocando el input
  &:focus + label {
    outline: 1px dotted grey ;
  }
}
