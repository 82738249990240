:enabled {
  .show-when-disabled {
    @extend .d-none;
  }
}

:disabled {
  .hide-when-disabled {
    @extend .d-none;
  }
}

:enabled {
  .show-when-disabled {
    @extend .d-none;
  }
}

:disabled {
  .hide-when-disabled {
    @extend .d-none;
  }
}

.no-scrollbar {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar { display: none; }
}

.font-small {
  font-size: .9rem;
}

.font-smaller {
  font-size: .8rem;
}

.font-bigger {
  font-size: 1.2rem;
}

.max-640 {
  max-width: 640px;
}

// TODO: Crear un mixin para todos los punteros posibles
.cursor-pointer { cursor: pointer !important; }

@each $grid-breakpoint, $_ in $grid-breakpoints {
  @include media-breakpoint-up($grid-breakpoint) {
    .w-#{$grid-breakpoint}-100 {
      width: 100% !important;
    }

    .w-#{$grid-breakpoint}-50 {
      width: 50% !important;
    }

    .border-#{$grid-breakpoint}         { border: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-top     { border-top: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-right   { border-right: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-bottom  { border-bottom: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-left    { border-left: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-0        { border: 0 !important; }
    .border-#{$grid-breakpoint}-top-0    { border-top: 0 !important; }
    .border-#{$grid-breakpoint}-right-0  { border-right: 0 !important; }
    .border-#{$grid-breakpoint}-bottom-0 { border-bottom: 0 !important; }
    .border-#{$grid-breakpoint}-left-0   { border-left: 0 !important; }

    .text-#{$grid-breakpoint}-left   { text-align: left !important; }
    .text-#{$grid-breakpoint}-right  { text-align: right !important; }
    .text-#{$grid-breakpoint}-center { text-align: center !important; }
  }
}

// Ocultar la barra de scroll, útil para sliders horizontales.
.no-scrollbar {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar { display: none; }
}

// Mismo alto que ancho
.square {
  @extend .position-relative;
  padding-bottom: 100%;

  & > * {
    @extend .position-absolute;
    @extend .top-0;
    @extend .left-0;
    @extend .h-100;
  }
}

// Eventos del cursor
@each $event in (none, auto) {
  .pointer-event-#{$event} {
    pointer-events: $event;
  }

  @each $grid-breakpoint, $_ in $grid-breakpoints {
    @include media-breakpoint-up($grid-breakpoint) {
      .pointer-event-#{$grid-breakpoint}-#{$event} {
        pointer-events: $event;
      }
    }
  }
}

// Agrega una transición a cualquier modificación de atributos
.transition {
  @include transition($transition-base);
}

// Ajustes de imagen y video con respecto al contenedor
@each $keyword in (contain, cover, fill, none, scale-down) {
  .fit-#{$keyword} {
    object-fit: #{$keyword};
  }

  @each $grid-breakpoint, $_ in $grid-breakpoints {
    @include media-breakpoint-up($grid-breakpoint) {
      .fit-#{$grid-breakpoint}-#{$keyword} {
        object-fit: #{$keyword};
      }
    }
  }
}

// Cursores
@each $cursor in (pointer none) {
  .cursor-#{$cursor} {
    cursor: $cursor;
  }
}

// Overflow
@each $value in (auto, hidden, scroll) {
  .overflow-#{$value} {
    overflow: $value !important;
  }

  @each $axis in (y, x) {
    .overflow-#{$axis}-#{$value} {
      overflow-#{$axis}: $value !important;
    }
  }

  @each $grid-breakpoint, $_ in $grid-breakpoints {
    @include media-breakpoint-up($grid-breakpoint) {
      .overflow-#{$grid-breakpoint}-#{$value} {
        overflow: $value !important;
      }

      @each $axis in (y, x) {
        .overflow-#{$grid-breakpoint}-#{$axis}-#{$value} {
          overflow-#{$axis}: $value !important;
        }
      }
    }
  }
}

@each $spacer, $length in $spacers {
  // Poder aumentar o disminuir el alto de la tipografía
  .f-#{$spacer} {
    font-size: $length !important;
  }

  // Columnas de texto
  .text-column-#{$spacer} {
    column-count: $spacer;
  }

  // Limitar la cantidad de líneas de un bloque de texto
  .line-clamp-#{$spacer} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $spacer;
    -webkit-box-orient: vertical;
  }

  // Ubicación absoluta, usar con position-*
  @each $direction in (top, right, bottom, left) {
    .#{$direction}-#{$spacer} {
      #{$direction}: $length
    }
  }

  @each $grid-breakpoint, $_ in $grid-breakpoints {
    @include media-breakpoint-up($grid-breakpoint) {
      // Poder aumentar o disminuir el alto de la tipografía
      .f-#{$grid-breakpoint}-#{$spacer} {
        font-size: $length !important;
      }

      // Columnas de texto
      .text-column-#{$grid-breakpoint}-#{$spacer} {
        column-count: $spacer;
      }

      // Limitar la cantidad de líneas de un bloque de texto
      .line-clamp-#{$grid-breakpoint}-#{$spacer} {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: $spacer;
        -webkit-box-orient: vertical;
      }

      // Ubicación absoluta, usar con position-*
      @each $direction in (top, right, bottom, left) {
        .#{$direction}-#{$grid-breakpoint}-#{$spacer} {
          #{$direction}: $length
        }
      }
    }
  }
}

// anchos y altos
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length; }
    .#{$abbrev}-#{$size} { #{$prop}: $length; }
    .min-#{$abbrev}-#{$size} { min-#{$prop}: $length; }
    .max-#{$abbrev}-#{$size} { max-#{$prop}: $length; }

    @each $grid-breakpoint, $_ in $grid-breakpoints {
      @include media-breakpoint-up($grid-breakpoint) {
        .#{$abbrev}-#{$grid-breakpoint}-#{$size} { #{$prop}: $length; }
        .min-#{$abbrev}-#{$grid-breakpoint}-#{$size} { min-#{$prop}: $length; }
        .max-#{$abbrev}-#{$grid-breakpoint}-#{$size} { max-#{$prop}: $length; }
      }
    }
  }
}

@each $scroll in (auto, smooth) {
  .scroll-#{$scroll} {
    scroll-behavior: #{$scroll};
  }

  @each $grid-breakpoint, $_ in $grid-breakpoints {
    @include media-breakpoint-up($grid-breakpoint) {
      .scroll-#{$grid-breakpoint}-#{$scroll} {
        scroll-behavior: #{$scroll};
      }
    }
  }
}

// Versiones responsive de utilidades Bootstrap
@each $grid-breakpoint, $_ in $grid-breakpoints {
  @include media-breakpoint-up($grid-breakpoint) {
    // Bordes
    // node_modules/bootstrap/scss/utilities/_borders.scss
    .border-#{$grid-breakpoint}         { border: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-top     { border-top: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-right   { border-right: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-bottom  { border-bottom: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-left    { border-left: $border-width solid $border-color !important; }
    .border-#{$grid-breakpoint}-0        { border: 0 !important; }
    .border-#{$grid-breakpoint}-top-0    { border-top: 0 !important; }
    .border-#{$grid-breakpoint}-right-0  { border-right: 0 !important; }
    .border-#{$grid-breakpoint}-bottom-0 { border-bottom: 0 !important; }
    .border-#{$grid-breakpoint}-left-0   { border-left: 0 !important; }

    // Alineación
    .text-#{$grid-breakpoint}-left   { text-align: left !important; }
    .text-#{$grid-breakpoint}-right  { text-align: right !important; }
    .text-#{$grid-breakpoint}-center { text-align: center !important; }

    // Posicionamiento
    @each $position in $positions {
      .position-#{$grid-breakpoint}-#{$position} { position: $position !important; }
    }

    // Anchos y altos
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}-#{$grid-breakpoint}-#{$size} { #{$prop}: $length !important; }
        .min-#{$abbrev}-#{$grid-breakpoint}-#{$size} { min-#{$prop}: $length !important; }
      }
    }
  }
}

// Crea una propiedad con prefijos de navegador
@mixin vendor-prefix($property, $definition...) {
  @each $prefix in $vendor-prefixes {
    #{$prefix}$property: $definition;
  }
}


input[type="radio"] + label span {
 & svg {
   opacity: 0.2;
 }
}

input[type="radio"]:checked + label span {
  color:#fff;
  @extend .btn-currency, .btn-tl ;
  & svg {
    opacity: 1;
  }
}



// Colores
@each $color, $rgb in $colors {
  .background-#{$color} {
    background-color: var(--#{$color});

    &:focus {
      background-color: var(--#{$color});
    }
  }

  @each $opacity, $degree in $opacities {
    .background-#{$color}-t#{$opacity} {
      background-color: scale-color($rgb, $alpha: $degree);
    }
  }

  @each $attr in (stroke, fill) {
    .#{$attr}-#{$color} {
      svg {
        * {
          #{$attr}: var(--#{$color});
        }
      }
    }
  }

  // Barras de scroll de colores
  .scrollbar-#{$color} {
    scrollbar-color: var(--#{$color}) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$color});
    }
  }

  // Bordes
  .border-#{$color} {
    border-color: var(--#{$color}) !important;
  }

  // Cambiar el fondo al pasar por encima o hacer foco
  .hover-bg-#{$color} {
    &:hover,
    &:focus-within {
      background-color: var(--#{$color}) !important;
    }
  }

  // Cambiar el color al pasar por encima o hacer foco
  .hover-#{$color} {
    &:hover,
    &:focus-within {
      color: var(--#{$color}) !important;
    }
  }

  // Cambiar el color
  .#{$color} {
    color: var(--#{$color}) !important;

    &:focus {
      color: var(--#{$color}) !important;
    }

    &:focus {
      color: var(--#{$color});
    }

    // Invertir el color en la selección
    ::-moz-selection,
    ::selection {
      background: var(--#{$color});
      color: white;
    }

    svg {
      * {
        fill: var(--#{$color});
      }
    }

    // Ajustes para Bootstrap
    .form-control {
      border-color: var(--#{$color});
      color: var(--#{$color});
    }

    .btn {
      background-color: var(--#{$color});
      color: white;
      border-color: var(--#{$color});
    }

    hr {
      border-color: var(--#{$color});
    }

    a {
      color: var(--#{$color}) !important;
    }
  }
}
