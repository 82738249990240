.blog {
  @include media-breakpoint-up('lg') {
    &:first-child,
    &:nth-child(3n+4) {
      align-items: flex-start !important;
    }

    &:nth-child(3n) {
      align-items: flex-end !important;
    }
  }

  .cover {
    width: 100%;
    height: 100%;
  }

  .inside {
    width: 100%;
  }

  @include media-breakpoint-up('lg') {
    .cover {
      width: 285px;
      height: 240px;
    }

    .inside {
      width: 285px;
    }
  }
}

.post-destacado {
  h1 {
    font-size: 2.5rem;
  }
}

blockquote {
  font-weight: 800;
  font-size: 1.3rem;
}

.img-main {
  object-fit: cover;
  width: 100%;
  height: 480px !important;

  @include media-breakpoint-up('lg') {
    width: 640px;
  }
}

@each $size in (1,3,5) {
  .line-clamp-#{$size} {
    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: $size;
      -webkit-box-orient: vertical;

      margin: 0;
    }
  }
}

.post {
  time {
    font-size: 0.9rem;
  }

  blockquote {
    @extend .m-5;
    @extend .pl-4;
    @extend .font-italic;

    border-left: 2px solid $brown;
  }

  img {
    @extend .img-fluid;
  }

  .e-content {
    a {
      color: $brown !important;
      text-decoration: underline;
    }
  }
}

.post-relacionado {
  img {
    width: 100%;
  }

  .inset {
    position: relative;

    p {
      position: absolute;
      bottom: 0;
    }
  }

  .authors {
    background-color: rgba(0,0,0,.75);
  }
}
