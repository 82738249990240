.floating-alert {
  pointer-events: none;

  &.floating-alert-bottom {
    bottom: 2rem;
  }

  .floating-alert-content {
    background-color: rgba(0,0,0,.8);
    border-radius: 2rem;
    color: $white;
    padding: .5rem 1rem;
  }
}
