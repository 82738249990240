.hyphens {
  @include vendor-prefix(hyphens, auto);
}

@each $grid-breakpoint, $_ in $grid-breakpoints {
  @include media-breakpoint-up($grid-breakpoint) {
    .hyphens-#{$grid-breakpoint} {
      @include vendor-prefix(hyphens, auto);
    }

    .text-#{$grid-breakpoint}-justify {
      text-align: justify !important;
    }
  }
}
