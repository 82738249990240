.backdropable {
  .backdrop {
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    visibility: hidden;
    opacity: 0;
    @include vendor-prefix(transition, all 0.5s $bezier);
  }

  &:focus,
  &:hover {
    .backdrop {
      visibility: visible;
      opacity: 1;
    }
  }
}

.libro {
  .cover {
    height: 370px;
    max-width: 100%;
    object-fit: cover;
  }

  .inside {
    width: 260px;
  }

  @include media-breakpoint-up('md') {
    font-size: .9rem !important;

    .cover {
      height: 300px;
      max-width: 280px;
    }

    .inside {
      width: 230px;
    }
  }
}

.book {
  .cover {
    max-height: 450px;
  }
}

.libro-chico {
  .cover {
    height: 370px;
    max-width: 100%;
    object-fit: cover;
  }

  .inside {
    width: 260px;
  }

  @include media-breakpoint-up('md') {
    font-size: .8rem;
    .btn { font-size: .62rem; }

    .cover {
      height: 185px;
      max-width: 168px;
    }

    .inside {
      width: 130px;
    }
  }
}
