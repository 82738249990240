@font-face {
  font-family: "Olsen TF";
  src: url("../fonts/OlsenTF-Regular-subset.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Olsen TF Italic";
  src: url("../fonts/OlsenTF-RegularItalic-subset.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Olsen TF Bold";
  src: url("../fonts/OlsenTF-Bold-subset.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Olsen TF Bold Italic";
  src: url("../fonts/OlsenTF-BoldItalic-subset.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular-subset.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../fonts/Montserrat-Medium-subset.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Medium Italic";
  src: url("../fonts/Montserrat-MediumItalic-subset.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../fonts/Montserrat-Light-subset.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat Italic";
  src: url("../fonts/Montserrat-RegularItalic-subset.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat Light Italic";
  src: url("../fonts/Montserrat-LightItalic-subset.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../fonts/Montserrat-Bold-subset.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat Bold Italic";
  src: url("../fonts/Montserrat-BoldItalic-subset.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

.font-olsen-tf {
  font-family: "Olsen TF";
}
