// 1rem = 14px
html { font-size: 87% }

$debug: false;
$enable-rounded: false;
$enable-responsive-font-sizes: true;
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.8;
$h2-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.1;
$lead-font-size: $font-size-base * 1.1;
$vendor-prefixes: ("", "-webkit-", "-ms-", "-o-", "-moz-");
$bezier: cubic-bezier(0.75, 0, 0.25, 1);
$input-group-addon-bg: none;

$gray-500: #adb5bd;
$gray-600: #606060;
$gray-light: #efefef;
$black: #000;
$brown: #e52d0e;

$box-shadow: 5px 5px 10px 0px $gray-500;
$border-color: $black;
$input-btn-focus-color: $brown;

$colors: (
  "brown": $brown,
  "black": $black,
  "gray-light": $gray-light,
  "gray-600": $gray-600
);

$sizes: (
"200px":200px
);

$theme-colors: (
  "tl": $black,
  "primary": $brown,
  "transparent": transparent
);

$spacer: 1rem;
$spacers: (
  7: $spacer * 7
);

$opacities: (
  0: -100%,
  1: -80%,
  2: -60%,
  3: -40%,
  4: -20%,
  5: 0%
);

/*
 * Redefinir variables de Boostrap acá.  Se las puede ver en
 * node_modules/bootstrap/scss/_variables.scss
 *
 * XXX: Solo importar los archivos necesarios de bootstrap para
 * facilitar la reducción de CSS.  Por ahora pedimos todo para poder
 * empezar a trabajar en el HTML sin pensar en CSS.
 */
@import "bootstrap/scss/bootstrap";
@import "fork-awesome/scss/fork-awesome";
@import "accessibility";
@import "helpers";
@import "hyphens";
@import "toggler";
@import "d_toggler";
@import "share_box";
@import "donacion";
@import "snap";
@import "fonts";
@import "navbar";
@import "slider";
@import "libro";
@import "blog";
@import "archive";
@import "cart";
@import "footer";
@import "floating-alert";
@import "editor";
@import "embed";
@import "snap";
@import "editor";
@import "menu";

h1, h2 { font-family: 'Olsen TF', sans-serif; }

.font-weight-bold,
strong {
  font-family: 'Montserrat Bold', sans-serif;
}

h3,
.font-weight-bolder {
  font-family: 'Montserrat Medium', sans-serif;
}

.font-weight-light {
  font-family: 'Montserrat Light', sans-serif;
}

nav,
footer,
section,
article {
  .inside {
    max-width: 992px;
  }
}

iframe {
  border: 0;
  width: 100%;
  min-height: 480px;
}

.btn-tl {
  @include button-variant($black, $black, $brown);
}
.black-d{
  color: var(--black);
}
.btn-currency{
  background: var(--black);
  color: $gray-light;
}

.btn-nube.active {
  background-color: #212529;
  color: white;
}

.turbolinks-progress-bar {
  background-color: $primary;
}

@if $debug {
  /*
   * Mostrar un borde alrededor de todos los elementos para poder
   * trabajar.
   */
  * { outline: 1px solid pink; }
}
