.slider {
  & > .inside {
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .controls {
    a {
      &.active,
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;

        i {
          @extend .fa-circle;
        }
      }
    }
  }

  .decoration {
    font-size: $h2-font-size;
  }

  .content {
    @extend .lead;
  }
}
