html {
  margin-top: 90px; /* La altura del navbar, más algunos píxeles de margen */
}

html,
body {
  scroll-padding-top: 90px; // https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/
}

.notification { z-index: 1031; }

nav {
  .active {
    @extend .font-weight-bold;
    @extend .brown;
  }

  .toggler {
    & + label {
      .icon-open {
        display: block;
      }

      .icon-close {
        display: none;
      }

      .toggled {
      }
    }

    &:checked + label {
      @include media-breakpoint-down('md') {
        z-index: 2;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;

        .icon-open {
          display: none;
        }

        .icon-close {
          display: block;
        }

        .toggled {
          display: block !important;
          z-index: 1;
        }
      }
    }
  }

  .logo {
    svg {
      width: 15rem;
    }
  }

  .cart-icon {
    // lg + 92.58px
    @media (min-width: 1085px) {
      margin-right: -50px;
    }
  }

  .search {
    width: 10rem;
  }
}
