/// El menú hamburguesa se expande cuando obtiene el foco y se oculta
/// cuando lo pierde.  Esto nos permite tener un menú dinámico sin
/// necesidad de JS.
///
/// @group Menú
/// @link _includes/menu.html
/// @todo En realidad el evento del foco está puesto en toda la navbar
/// en lugar de solo en el ícono de hamburguesa.
/// @example html
///   <nav class="navbar">
///     <button class="navbar-toggler">
///       <span class="navbar-toggler-icon"></span>
///     </button>
///
///     <div class="collapse navbar-collapse" id="menu"></div>
///   </nav>
.nav {
  &:focus-within {
    .navbar-collapse {
      @extend .d-block;
    }
  }

  .dropdown {
    .dropdown-menu {
      @extend .d-none;
    }

    &:hover,
    &:focus-within {
      .dropdown-menu {
        @extend .d-block;
      }
    }
  }
}
