.autorxs {
  .toggler {
    & + label {
      h2 {
        font-family: 'Montserrat', sans-serif;
      }

      .toggled {
        display: none;
      }
    }

    &:checked + label {
      h2 {
        font-family: 'Montserrat Medium', sans-serif;
        font-weight: 600;
      }

      .toggled {
        display: block;
      }
    }
  }
}
